const { sin, PI } = Math;

export function updateHeroHeadingContent() {
  const heading = document.querySelector(".hero h1");
  if (!heading) return;

  const length = heading.textContent.length;
  heading.innerHTML = [...heading.textContent]
    .map((c, i) => {
      const scale = 1 - 0.25 * sin(((i + 1) / (length + 1)) * PI);
      return `<span style="--scale: ${scale}">${c}</span>`;
    })
    .join("");
}
